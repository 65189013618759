import './App.css';
import leftIcon from './icons/arrow-left.svg';
import rightIcon from './icons/arrow-right.svg';

// Header Component
function Header() {
  return (
    <header id="headerStyle">
      <section id="headerSection">
        
        <div id="logoStyle">
          <p>Dope Quotes</p>
        </div>

        <div id="categoriesStyle">
          <section id="categories">
            <span>Music</span>
            <span>TV Shows</span>
            <span>Movies</span>
          </section>
        </div>

        <div id="logoStyle">
        </div>

      </section>
    </header>
  );
};

// Body Component
function Body() {
  return (
    <section id="bodyStyle">
      <div id="quote">
        <p>
          Neo, sooner or later you're going to realize just as I did that there's a difference 
          between knowing the path and walking the path.
        </p>
      </div>
      <div id="author">
        <p>
          Morpheus
        </p>
      </div>
      <div id="source">
        <p>
          The Matrix, 1999
        </p>
      </div>
      <div id="navigationStyle">
          <button>
            <img src={leftIcon} alt="icon"/>
          </button>
          <button>
            <img src={rightIcon} alt="icon"/>
          </button>
      </div>
    </section>
  );
};

// Footer Component
function Footer() {
  return (
    <footer id="footerStyle">
      <div id="footerContentStyle"> 
        <p>
          K.O. Studios
        </p> 
      </div>
    </footer>
  );
};




// Main App Component
const App = () => {
  return (
    <div>
      <Header />
      <Body />
      <Footer />
    </div>
  );
};

// Export the main App component
export default App;